import { memo, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Checkbox, FormControlLabel, Typography } from "@mui/material";

type Props = Readonly<{
	isRocidDict: boolean;
	filterValue: string;
	searchAll: boolean;
	searchByRegex: boolean;
	onFilterChange: (value: string) => void;
	onSearchByRegexToggle: (event, isInputChecked: boolean) => void;
	onSearchInDataToggle: (event, isInputChecked: boolean) => void;
	searchResultLength: number;
}>;

const TableEditorFilter = ({ isRocidDict, filterValue = "", searchAll, searchByRegex, onFilterChange, onSearchByRegexToggle, onSearchInDataToggle, searchResultLength }: Props) => {
	const { t } = useTranslation();
	const [query, setQuery] = useState(filterValue);

	const timeoutIdRef = useRef<number | undefined>(undefined);

	useEffect(() => (
		() => {
			window.clearTimeout(timeoutIdRef.current);
		}
	), []);

	useEffect(() => {
		setQuery(filterValue);
	}, [filterValue]);

	useEffect(() => {
		window.clearTimeout(timeoutIdRef.current);

		timeoutIdRef.current = window.setTimeout(() => {
			onFilterChange(query);
		}, 300);
	}, [query]);

	return (
		<>
			<div style={{ display: "flex", flexDirection: isRocidDict ? "row" : "column", alignItems: "center", margin: "16px 16px 4px" }}>
				<TextField
					placeholder={t("teditor.search")}
					fullWidth={true}
					autoFocus={true}
					value={query}
					onChange={(event) => (setQuery(event.target.value))}
					style={{ margin: "0 10px" }}
				/>
				<div style={{display: "flex"}}>
					<FormControlLabel
						label={t("teditor.searchAll")}
						control={<Checkbox checked={searchAll} onChange={onSearchInDataToggle} style={{ marginLeft: "16px" }} />}
						style={{ whiteSpace: "nowrap" }}
					/>
					<FormControlLabel
						label={t("teditor.searchByRegex")}
						control={<Checkbox checked={searchByRegex} onChange={onSearchByRegexToggle} />}
						style={{ whiteSpace: "nowrap" }}
					/>
				</div>
			</div>
			{(searchResultLength === 0) ?
				<Typography variant="subtitle2" style={{margin: "8px 16px"}}>
					{isRocidDict ? null : t("teditor.noRecords")}
				</Typography>
				:
				<Typography variant="body2" color="textSecondary" style={isRocidDict ? {margin: "8px 26px"} : {margin: "8px 16px"}}>
					{(searchResultLength === 1) ? t("teditor.oneEntryFound") : t("teditor.entriesFound", { COUNT: searchResultLength })}
				</Typography>
			}
		</>
	);
};

export default memo(TableEditorFilter);
