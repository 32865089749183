import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Divider,
	Collapse,
	List,
	ListItemButton,
	ListItemText,
	Avatar,
	Typography,
	ListItemIcon,
	TextField,
	Switch,
	Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import RowEntry from "../../../row-entry";
import Svg from "../../../svg";
// services
import { icons } from "@local/theme";
// types
import type { ChangeEvent, SyntheticEvent } from "react";

type Props = Readonly<{
	selectedItem: any;
	previousItem: any;
	fieldUpdateCount: number;
	staticEndpointDataStringValid: boolean;
	fieldUpdate: (event: SyntheticEvent) => void;
	onToggleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	onActionJsonChange: (event: SyntheticEvent) => void;
}>;

const DEFAULT_SELECTED_ITEM = {
	stringOverrideKey: "",
	staticEndpointData: null,
	RxOnWhenIdle: false,
};

const AdvancedSection = ({ selectedItem = DEFAULT_SELECTED_ITEM, previousItem, fieldUpdateCount, staticEndpointDataStringValid, fieldUpdate, onToggleChange, onActionJsonChange }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(false);

	const hasJsonChanged = (key) => {
		try {
			if (selectedItem[key] && selectedItem[key] !== null) {
				if (typeof selectedItem[key] === "string") {
					return selectedItem[key] !== JSON.stringify(previousItem[key] ?? [], null, 2);
				}
				return JSON.stringify(selectedItem[key], null, 2) !== JSON.stringify(previousItem[key] ?? [], null, 2);
			}
			return false;
		} catch (_error) {
			return false;
		}
	};

	return (
		<List disablePadding={true}>
			<ListItemButton
				onClick={() => (setExpanded(!expanded))}
				style={{ paddingTop: "12px", paddingBottom: "12px" }}
			>
				<ListItemText
					primary={
						<Typography variant="body2" style={{ fontWeight: "bold" }}>
							{t("customtEditor.sections.advanced")}
						</Typography>
					}
				/>
				<Avatar className="count-badge">{fieldUpdateCount}</Avatar>
				<ListItemIcon>
					{expanded
						? <icons.ExpandLess htmlColor={theme.palette.text.secondary} />
						: <Svg src="navigation/arrowDropDown.svg" color={theme.palette.text.secondary} />
					}
				</ListItemIcon>
			</ListItemButton>
			<Divider />
			<Collapse in={expanded} timeout="auto">
				<RowEntry title={t("customtEditor.stringOverrideKey.text")} subTitle={t("customtEditor.stringOverrideKey.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.stringOverrideKey !== selectedItem.stringOverrideKey &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.stringOverrideKey ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="stringOverrideKey"
						value={selectedItem.stringOverrideKey ?? ""}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.staticEndpointData.text")} subTitle={t("customtEditor.staticEndpointData.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{(hasJsonChanged("staticEndpointData") && staticEndpointDataStringValid) &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={
								(previousItem.staticEndpointData && previousItem.staticEndpointData !== null) ?
									((typeof previousItem.staticEndpointData === "string") ?
										previousItem.staticEndpointData
										:
										JSON.stringify(previousItem.staticEndpointData, null, 2))
									:
									"NA"
							}
							>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						name="staticEndpointData"
						value={
							(selectedItem.staticEndpointData && selectedItem.staticEndpointData !== null) ?
								((typeof selectedItem.staticEndpointData === "string") ?
									selectedItem.staticEndpointData
									:
									JSON.stringify(selectedItem.staticEndpointData, null, 2))
								:
								""
						}
						placeholder="[]"
						multiline={true}
						fullWidth={true}
						error={!staticEndpointDataStringValid}
						onChange={onActionJsonChange}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.RxOnWhenIdle.text")} subTitle={t("customtEditor.RxOnWhenIdle.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.RxOnWhenIdle !== selectedItem.RxOnWhenIdle &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.RxOnWhenIdle === undefined ? "NA" : previousItem.RxOnWhenIdle.toString()}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<div style={{ width: "calc(100% - 56px)" }}>
						<Switch
							name="RxOnWhenIdle"
							checked={selectedItem.RxOnWhenIdle ?? false}
							onChange={onToggleChange}
						/>
					</div>
				</RowEntry>
				<Divider />
			</Collapse>
		</List>
	);
};

export default AdvancedSection;
