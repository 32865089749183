import { Component, createRef } from "react";
import { findDOMNode } from "react-dom";
import { Paper, TextField } from "@mui/material";
// Services
import Responsive from "../../../services/responsive";

type ReviewData = Array<ReviewItem>;
interface ReviewItem {
	label: string;
	oldVal: any;
	newVal: any;
}

type Props = Readonly<{
	selectedItem: any;
	onDataStringInvalid: () => void;
	onActionJsonUpdate: (json: object, reviewData: ReviewData) => void;
}>;

type State = {
	dataString: string;
	dataStringValid: boolean;
	containerHeight: number;
};

class RawJsonEditor extends Component<Props, State> {

	#refContainerElement = createRef();
	#reviewData: ReviewData;
	#previousItem: any;

	constructor(props: Props) {
		super(props);

		this.state = {
			dataString: JSON.stringify(props.selectedItem ?? {}, null, 2),
			dataStringValid: true,
			containerHeight: 510,
		};

		this.#reviewData = [];
		this.#previousItem = props.selectedItem;

		this.handleScreenSizeChanged = this.handleScreenSizeChanged.bind(this);
		this.handleActionJsonChange = this.handleActionJsonChange.bind(this);
	}

	override componentDidMount() {
		Responsive.on("screenSizeChanged", this.handleScreenSizeChanged);

		this.handleScreenSizeChanged();
	}

	override componentWillUnmount() {
		Responsive.off("screenSizeChanged", this.handleScreenSizeChanged);
	}

	handleScreenSizeChanged() {
		const element = findDOMNode(this.#refContainerElement.current); // eslint-disable-line react/no-find-dom-node
		const height = window.innerHeight - element.getBoundingClientRect().top;
		this.setState({
			containerHeight: height - 48 - 36,
		});
	}

	getJsonReviewData(name, newValue) {
		const index = this.#reviewData.findIndex((item) => (item && item.label === name));
		const oldValue = JSON.stringify(this.#previousItem, null, 2);
		const newVal = JSON.stringify(JSON.parse(newValue), null, 2);
		if (index === -1 && oldValue !== newVal) {
			this.#reviewData.push({
				label: name,
				oldVal: oldValue,
				newVal: newValue,
			});
		} else if (oldValue === newVal) {
			this.#reviewData.splice(index, 1);
		} else {
			this.#reviewData[index] = {
				label: name,
				oldVal: oldValue,
				newVal: newValue,
			};
		}
		return this.#reviewData;
	}

	handleActionJsonChange(event) {
		const newValue = event.target.value;
		const name = event.target.name;
		try {
			const json = JSON.parse(newValue);
			this.setState({
				dataString: newValue,
				dataStringValid: true,
			}, () => {
				this.props.onActionJsonUpdate(json, this.getJsonReviewData(name, newValue));
			});
		} catch (_error) {
			this.props.onDataStringInvalid();
			this.setState({
				dataString: newValue,
				dataStringValid: false,
			});
		}
	}

	override render() {
		return (
			<Paper
				className="card-separator json-editor"
				ref={this.#refContainerElement}
				style={{ overflow: "auto", height: this.state.containerHeight, border: this.state.dataStringValid ? "none" : "2px solid red"}}
			>
				<TextField
					name="raw_editor"
					placeholder="{}"
					fullWidth={true}
					multiline={true}
					value={this.state.dataString}
					onChange={this.handleActionJsonChange}
				/>
			</Paper>
		);
	}

}

export default RawJsonEditor;
