import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
	Table,
	TableBody,
	TableHead,
	TableCell,
	IconButton,
	TableRow,
	ListItemIcon,
	Typography,
	Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import withNavigateAndParams from "../withNavigateAndParams";
import Na from "../na";
import Svg from "../svg";
// services
import { icons } from "@local/theme";

type Props = Readonly<{
	hasWriteAccess: boolean;
	filteredData: any; //TODO
	onDuplicateClickHandler: (isDuplicate: boolean, tableItem: any) => void;
	onDeleteClick: (tableItem: any) => void;
}>;

const TableEditorAdvanced = ({ hasWriteAccess, filteredData, onDuplicateClickHandler, onDeleteClick }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const columnStyle = {
		paddingRight: "5px",
		paddingLeft: "30px",
		whiteSpace: "normal",
		overflow: "inherit",
		wordBreak: "break-word",
	};

	const headingStyle = {
		paddingRight: "5px",
		paddingLeft: "30px",
	};

	const handleTableItemClick = (tableItem) => {
		void navigate(`./${tableItem.id.string_key}`);
	};

	const handleDuplicateClick = (event, tableItem) => {
		event.stopPropagation();
		onDuplicateClickHandler(true, tableItem);
	};

	const handleDeleteClick = (event, tableItem) => {
		event.stopPropagation();
		onDeleteClick(tableItem);
	};

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell sx={headingStyle}>{t("teditor.key")}</TableCell>
					<TableCell sx={headingStyle}>{t("teditor.name")}</TableCell>
					<TableCell sx={headingStyle}>{t("teditor.type")}</TableCell>
					<TableCell sx={headingStyle}>{t("teditor.category")}</TableCell>
					<TableCell sx={headingStyle}>{t("teditor.help")}</TableCell>
					<TableCell sx={headingStyle} />
				</TableRow>
			</TableHead>
			<TableBody>
				{(filteredData.length === 0) ?
					<TableRow className="last-row-no-border" sx={{ height: "62px" }}>
						<TableCell colSpan={7} sx={columnStyle} padding="none">
							<Typography variant="h5" sx={{ fontSize: "1.1rem" }}>
								{t("teditor.noRecords")}
							</Typography>
						</TableCell>
					</TableRow>
					:
					filteredData.map((tableItem) => (
						<TableRow
							key={tableItem.id.string_key}
							hover={true}
							onClick={() => (handleTableItemClick(tableItem))}
							className="last-row-no-border"
							sx={{ height: "62px", cursor: "pointer" }}
						>
							<TableCell sx={columnStyle} padding="none">{tableItem.id.string_key ?? <Na />}</TableCell>
							<TableCell sx={columnStyle} padding="none">
								<div>{tableItem.data.mfg_name ?? <Na />}</div>
								<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
									{tableItem.data.mfg_model ?? ""}
								</div>
							</TableCell>
							<TableCell sx={columnStyle} padding="none">
								<div>{t(`rocIds.${tableItem.data.type}`) || <Na />}</div>
								<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
									{tableItem.data.type ?? ""}
								</div>
							</TableCell>
							<TableCell sx={columnStyle} padding="none">
								<div>{t(`deviceCategories.${tableItem.data.category}`) || <Na />}</div>
								<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
									{tableItem.data.category ?? ""}
								</div>
							</TableCell>
							<TableCell sx={columnStyle} padding="none">
								{tableItem.data.mfg_help &&
									<ListItemIcon>
										<IconButton component={Link} href={tableItem.data.mfg_help} target="_blank" onClick={(event) => (event.stopPropagation())}>
											<Svg src="navigation/openNewWindow.svg" color={theme.palette.primary.main} />
										</IconButton>
									</ListItemIcon>
								}
							</TableCell>
							<TableCell sx={{ ...columnStyle, textAlign: "right" }} padding="none">
								<ListItemIcon>
									<IconButton
										title={t("teditor.duplicate")}
										disabled={!hasWriteAccess}
										onClick={(event) => (handleDuplicateClick(event, tableItem))}
									>
										<icons.CopyContent htmlColor={hasWriteAccess ? theme.palette.primary.main : theme.palette.text.secondary} />
									</IconButton>
								</ListItemIcon>
								<ListItemIcon>
									<IconButton
										title={t("teditor.remove")}
										disabled={!hasWriteAccess}
										onClick={(event) => (handleDeleteClick(event, tableItem))}
									>
										<Svg src="actions/delete.svg" color={hasWriteAccess ? theme.palette.primary.main : theme.palette.text.secondary} />
									</IconButton>
								</ListItemIcon>
							</TableCell>
						</TableRow>
					))
				}
			</TableBody>
		</Table>
	);
};

export default withNavigateAndParams(TableEditorAdvanced);
