import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Card,
	CardHeader,
	CardContent,
	CircularProgress,
	Checkbox,
	ListItem,
	ListItemText,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";
// services
import Gupport from "../../services/gupport";
// types
import type { CmdGetBackupTables, CmdGetBackup } from "../../types/gupport";

const ExportBackup = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [payload, setPayload] = useState(null);
	const [backupUrl, setBackupUrl] = useState(null);
	const [selectedTables, setSelectedTables] = useState([]);
	const [openErrorDialog, setOpenErrorDialog] = useState(false);
	const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

	useEffect(() => {
		const cmd = {
			action: "getBackupTables",
		} as const satisfies CmdGetBackupTables;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setLoading(false);
				setOpenSuccessDialog(false);
				setError(null);
				setSelectedTables([]);
				setPayload(msg.payload);
				setOpenErrorDialog(false);
				setBackupUrl(null);
			} else {
				setLoading(false);
				setError(error);
				setSelectedTables([]);
				setPayload(null);
				setOpenErrorDialog(true);
				setBackupUrl(null);
			}
		});
	}, []);

	const saveTables = () => {
		const cmd = {
			action: "getBackup",
			tables: selectedTables,
		} as const satisfies CmdGetBackup;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setOpenSuccessDialog(true);
				setBackupUrl(msg.payload.data.metaUrl);
			} else {
				setOpenErrorDialog(true);
				setBackupUrl(null);
			}
		});
	};

	const handleRowSelected = (value, event, isInputChecked) => {
		setSelectedTables((prevSelectedTables) => {
			const _selectedTables = prevSelectedTables.slice();
			if (isInputChecked) {
				_selectedTables.push(value);
			} else {
				_selectedTables.splice(_selectedTables.indexOf(value), 1);
			}
			return {
				_selectedTables,
			};
		});
	};

	if (loading) {
		return <CircularProgress style={{ margin: "auto", display: "block" }} />;
	}
	if (error) {
		return <div>{t("generic.errorWithMsg", { msg: "" })}<pre>{error.message}</pre></div>;
	}
	if (!payload) {
		return null;
	}

	return (
		<div style={{ maxWidth: "700px", margin: "0 auto" }}>
			{
				payload.dicts.map((category, dictIndex) => (
					<Card key={dictIndex} style={{ marginTop: "10px", overflow: "hidden" }}>
						<CardHeader
							title={`${category.desc} (${category.id})`}
							actAsExpander={true}
							showExpandableButton={false}
						/>
						<CardContent expandable={false}>
							<Table>
								<TableBody>
									{category.tables.map((table, tableIndex) => (
										<TableRow key={tableIndex} className="last-row-no-border">
											<TableCell style={{ width: "72px" }}>
												<Checkbox
													checked={selectedTables.includes(table)}
													onChange={handleRowSelected}
												/>
											</TableCell>
											<TableCell>
												<ListItem disabled={true}>
													<ListItemText primary={table.name} secondary={`Count: ${table.count}, Size: ${table.size}`} />
												</ListItem>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</CardContent>
					</Card>
				))
			}
			<div style={{ marginTop: "24px" }}>
				<Button
					variant="contained"
					disabled={selectedTables.length === 0}
					style={{ marginBottom: "24px", float: "right" }}
					onClick={saveTables}
				>
					{t("backup.exportBtn")}
				</Button>
			</div>
			<Dialog
				fullWidth={true}
				open={openErrorDialog}
				onClose={() => (setOpenErrorDialog(false))}
			>
				<DialogTitle>{t("backup.exportErrorTitle")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t("backup.exportErrorMsg")}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => (setOpenErrorDialog(false))}>{t("dialog.ok")}</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				open={openSuccessDialog}
				onClose={() => (setOpenSuccessDialog(false))}
			>
				<DialogTitle>{t("backup.exportSuccessTitle")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t(`backup.exportSuccessMsg\n${backupUrl}`)}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => (setOpenSuccessDialog(false))}>{t("dialog.ok")}</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ExportBackup;
