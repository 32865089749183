import { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "@mui/material";
// cmp
import MetadataJSONEditor from "./metadata-json-editor";
import Svg from "../svg";
// services
import Gupport from "../../services/gupport";
import { icons } from "@local/theme";
// types
import type { WithTranslation } from "react-i18next";
import type { TableNameId, CmdUpdateTable } from "../../types/gupport";

type Props = Readonly<WithTranslation & {
	tableId: TableNameId;
	hasWriteAccess: boolean;
	tableItemSelected: any; //TODO
	containerHeight: number;
	onItemUpdate: (data: any) => void; //TODO
	onRemoveClick: () => void;
}>;

type State = {
	inProgress: boolean;
	data: any;
	dataString: string;
	dataStringValid: boolean;
};

class TableEditorRight extends Component<Props, State> {

	public static defaultProps = {
		tableItemSelected: null,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			inProgress: false,
			data: {},
			dataString: "",
			dataStringValid: true,
		};

		this.handleActionJsonChange = this.handleActionJsonChange.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
	}

	override componentDidUpdate(prevProps) {
		if (this.props.tableId !== prevProps.tableId) {
			this.setState({
				data: {},
				dataString: "",
				dataStringValid: true,
			});
		}
		if (JSON.stringify(this.props.tableItemSelected) !== JSON.stringify(prevProps.tableItemSelected)) {
			if (this.props.tableItemSelected === null) {
				this.setState({
					data: {},
					dataString: "",
					dataStringValid: true,
				});
			} else {
				this.setState({
					data: this.props.tableItemSelected.data,
					dataString: JSON.stringify(this.props.tableItemSelected.data, null, 2),
					dataStringValid: true,
					inProgress: false,
				});
			}
		}
	}

	handleActionJsonChange(value) {
		try {
			const json = JSON.parse(value);
			this.setState({
				data: json,
				dataString: value,
				dataStringValid: true,
			});
		} catch (_error) {
			this.setState({
				dataString: value,
				dataStringValid: false,
			});
		}
	}

	handleSaveClick() {
		this.setState({
			inProgress: true,
		});
		const cmd = {
			action: "updateTable",
			tableName: this.props.tableId,
			data: [{
				id: this.props.tableItemSelected.id.string_key ?? this.props.tableItemSelected.id,
				data: this.state.data,
			}],
		} as const satisfies CmdUpdateTable;
		Gupport.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				const tableItem = msg.payload.data.find((tableItem) => (JSON.stringify(tableItem.id) === JSON.stringify(this.props.tableItemSelected.id)));
				this.setState({
					inProgress: false,
					data: tableItem.data,
					dataString: JSON.stringify(tableItem.data, null, 2),
					dataStringValid: true,
				});
				this.props.onItemUpdate(msg.payload.data);
			} else {
				this.setState({
					inProgress: false,
					data: {},
					dataString: "",
					dataStringValid: true,
				});
			}
		});
	}

	override render() {
		const { t, containerHeight, hasWriteAccess, tableItemSelected, onRemoveClick } = this.props;
		const dynamicHeight = containerHeight - 36 - 46 - (hasWriteAccess ? 0 : 60);

		return (
			<div style={{ width: "66%", paddingLeft: "16px" }}>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "48px" }}>
					<div style={{ fontWeight: "bold" }}>{t("teditor.data")}</div>
					<div>
						<Button
							variant="contained"
							size="small"
							startIcon={<icons.SaveIcon />}
							loading={this.state.inProgress}
							disabled={tableItemSelected === null || !this.state.dataStringValid || !hasWriteAccess || JSON.stringify(tableItemSelected.data) === JSON.stringify(this.state.data)}
							onClick={this.handleSaveClick}
							style={{marginRight: "10px"}}
						>
							{t("teditor.save")}
						</Button>
						<Button
							variant="contained"
							color="danger"
							size="small"
							startIcon={<Svg src="actions/delete.svg" size={18} />}
							disabled={(tableItemSelected === null || !hasWriteAccess)}
							onClick={onRemoveClick}
						>
							{t("teditor.remove")}
						</Button>
					</div>
				</div>
				{tableItemSelected
					?
					<div style={{ overflow: "auto", marginTop: "16px", height: dynamicHeight + 56 }}>
						<MetadataJSONEditor dataString={this.state.dataString} onActionJsonChange={this.handleActionJsonChange} />
					</div>
					:
					<h4 style={{ padding: "1em", textAlign: "center" }}>
						{t("teditor.selectAnItem")}
					</h4>
				}
			</div>
		);
	}

}

export default withTranslation()(TableEditorRight);
