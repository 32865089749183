import { useTranslation } from "react-i18next";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@mui/material";

type Props = Readonly<{
	errorMsg: string;
	onClose: () => void;
}>;

const ErrorDialog = ({ errorMsg, onClose }: Props) => {
	const { t } = useTranslation();

	return (
		<Dialog
			fullWidth={true}
			open={true}
			onClose={onClose}
		>
			<DialogTitle>{t("customtEditor.errors.title")}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{errorMsg}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="inherit" onClick={onClose}>{t("dialog.close")}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ErrorDialog;
