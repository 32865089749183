import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {
	Divider,
	Collapse,
	List,
	ListItemButton,
	ListItemText,
	Avatar,
	Typography,
	ListItemIcon,
	Switch,
	Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import RowEntry from "../../../row-entry";
import EditableSelectList from "../editable-select";
import Svg from "../../../svg";
// services
import type { ChangeEvent } from "react";
import { icons } from "@local/theme";

type Props = Readonly<{
	selectedItem: any;
	previousItem: any;
	fieldUpdateCount: number;
	onChange: (key: string, value: string) => void;
	onToggleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}>;

const createOptionsFromList = (list) => (
	list.map((item) => ({
		id: item,
		label: item,
		primary: item,
		secondary: "",
	}))
);

const OPTIONS = {
	ICONS: createOptionsFromList(i18next.t("icons", { returnObjects: true })),
	ICONS_A: createOptionsFromList(i18next.t("iconsA", { returnObjects: true })),
	ICONS_B: createOptionsFromList(i18next.t("iconsB", { returnObjects: true })),
};

const DEFAULT_SELECTED_ITEMS = {
	selectedItem: {
		icon: "",
		icon_a: "",
		icon_b: "",
		icon_blink: false,
	},
};

const IconsSection = ({ selectedItem = DEFAULT_SELECTED_ITEMS, previousItem, fieldUpdateCount, onChange, onToggleChange }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(false);

	return (
		<List disablePadding={true}>
			<ListItemButton
				onClick={() => (setExpanded(!expanded))}
				style={{ paddingTop: "12px", paddingBottom: "12px" }}
			>
				<ListItemText
					primary={
						<Typography variant="body2" style={{ fontWeight: "bold" }}>
							{t("customtEditor.sections.icon")}
						</Typography>
					}
				/>
				<Avatar className="count-badge">{fieldUpdateCount}</Avatar>
				<ListItemIcon>
					{expanded
						? <icons.ExpandLess htmlColor={theme.palette.text.secondary} />
						: <Svg src="navigation/arrowDropDown.svg" color={theme.palette.text.secondary} />
					}
				</ListItemIcon>
			</ListItemButton>
			<Divider />
			<Collapse in={expanded} timeout="auto">
				<RowEntry title={t("customtEditor.icon.text")} subTitle={t("customtEditor.icon.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.icon !== selectedItem.icon &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.icon ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<EditableSelectList
						name="icon"
						placeholder="di_*"
						value={selectedItem?.icon ?? ""}
						options={OPTIONS.ICONS}
						onChange={onChange}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.icon_a.text")} subTitle={t("customtEditor.icon_a.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.icon_a !== selectedItem.icon_a &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.icon_a ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<EditableSelectList
						name="icon_a"
						placeholder="di_*_a"
						value={selectedItem?.icon_a ?? ""}
						options={OPTIONS.ICONS_A}
						onChange={onChange}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.icon_b.text")} subTitle={t("customtEditor.icon_b.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.icon_b !== selectedItem.icon_b &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.icon_b ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<EditableSelectList
						name="icon_b"
						placeholder="di_*_b"
						value={selectedItem?.icon_b ?? ""}
						options={OPTIONS.ICONS_B}
						onChange={onChange}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.icon_blink.text")} subTitle={t("customtEditor.icon_blink.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.icon_blink !== selectedItem.icon_blink &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.icon_blink === undefined ? "NA" : previousItem.icon_blink.toString()}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<div style={{ width: "calc(100% - 56px)" }}>
						<Switch
							name="icon_blink"
							checked={selectedItem.icon_blink ?? false}
							onChange={onToggleChange}
						/>
					</div>
				</RowEntry>
				<Divider />
			</Collapse>
		</List>
	);
};

export default IconsSection;
