import { memo, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";
// types
import type { ReadonlyDeep } from "type-fest";
import type { ChartData, ChartOptions } from "chart.js";
import type { RocId, RocIdData } from "../../types/roc-table";
import type { DeviceBucketData } from "../../types/gupport";
// lazy
const Bar = lazy(() => import("../charts/bar-chart"));

type Props = Readonly<{
	container: boolean;
	selectedDeviceStat: ReadonlyDeep<DeviceBucketData> | null;
	rocIdTableData: Array<RocIdData> | undefined;
}>;

const GatewayDeviceStatChart = ({ container, selectedDeviceStat, rocIdTableData }: Props) => {
	const { t } = useTranslation();

	if (!selectedDeviceStat) {
		return null;
	}

	const findColor = (rocId: RocId) => {
		if (rocIdTableData) {
			const rocIdData = rocIdTableData.find((data) => (data.rocid === rocId));
			if (rocIdData) {
				const color = rocIdData.color;
				return color && color.length === 9 ? color.substring(0, 7) : color;
			}
		}
		return "#27ae60"; // using default from GW chart
	};

	const options = {
		plugins: {
			legend: {
				display: false,
			},
		},
	} as const satisfies ChartOptions<"bar">;

	if (selectedDeviceStat.rocid.buckets.length > 0) {
		const { data, bgColors, labels } = selectedDeviceStat.rocid.buckets.reduce((bucketObj, bucket) => {
			bucketObj.data.push(bucket.count);
			bucketObj.bgColors.push(findColor(bucket.val));
			bucketObj.labels.push(bucket.val);
			return bucketObj;
		}, { data: [] as Array<number>, bgColors: [] as Array<string | undefined>, labels: [] as Array<RocId>});

		const barChartData = {
			datasets: [{
				data: data,
				backgroundColor: bgColors,
				label: t("welcome.deviceChartTT"), // for legend
			}],
			labels: labels,
		} as const satisfies ChartData<"bar">;

		return (
			<div style={container ? { width: "50%", float: "left" } : { width: "100%" }}>
				<h3>{t("welcome.deviceStatTitle")}</h3>
				<Suspense fallback={<Skeleton animation="wave" variant="rectangular" height={43} />}>
					<Bar width={100} height={70} options={options} data={barChartData} />
				</Suspense>
			</div>
		);
	}
	return null;
};

export default memo(GatewayDeviceStatChart);
