import { Component } from "react";
import { Paper } from "@mui/material";
// cmp
import InfoSection from "./sections/info-section";
import IconsSection from "./sections/IconsSection";
import ManufacturerSection from "./sections/manufacturer-section";
import HelpsSection from "./sections/helps-section";
import AdvancedSection from "./sections/AdvancedSection";
import DeprecatedSection from "./sections/DeprecatedSection";

type ReviewData = Array<ReviewItem>;
interface ReviewItem {
	label: string;
	oldVal: any;
	newVal: any;
}

type Props = Readonly<{
	selectedItem: any;
	onActionJsonUpdate: (json: object, reviewData: ReviewData) => void;
}>;

type State = {
	staticEndpointDataStringValid: boolean;
	tagsStringValid: boolean;
	tableItemSelected: any;
};

const SECTIONS = {
	INFO: ["rocid", "category", "type", "endpoint", "quickview_category", "is_group", "is_presence", "plugin", "color"],
	ICON: ["icon", "icon_a", "icon_b", "icon_blink"],
	MANUFACTURER: ["mfg_name", "mfg_model", "mfg_help", "mfg_fw"],
	HELPS: ["removing_help", "wakeup_help"],
	ADVANCED: ["stringOverrideKey", "staticEndpointData", "RxOnWhenIdle"],
	DEPRECATED: ["device_details_native", "help", "tags"],
} as const;

class GuiJsonEditor extends Component<Props, State> {

	#reviewData: ReviewData;
	#previousItem: any;

	constructor(props: Props) {
		super(props);

		this.state = {
			staticEndpointDataStringValid: true,
			tagsStringValid: true,
			tableItemSelected: props.selectedItem,
		};

		this.#reviewData = [];
		this.#previousItem = props.selectedItem;

		this.fieldUpdate = this.fieldUpdate.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.getReviewData = this.getReviewData.bind(this);
		this.getFieldUpdateCount = this.getFieldUpdateCount.bind(this);
		this.handleToggleChange = this.handleToggleChange.bind(this);
		this.handleDataJsonChange = this.handleDataJsonChange.bind(this);
	}

	handleToggleChange(event) {
		const newValue = event.target.checked;
		const name = event.target.name;
		this.setState((prevState) => ({
			tableItemSelected: { ...prevState.tableItemSelected, [name]: newValue },
		}), () => {
			this.props.onActionJsonUpdate(this.state.tableItemSelected, this.getReviewData(name, newValue));
		});
	}

	handleChange(key, value) {
		const data = {
			[key]: value,
		};
		if (key === "icon") {
			data[`${key}_a`] = `${value}_a`;
			data[`${key}_b`] = `${value}_b`;
		}
		this.setState((prevState) => ({
			tableItemSelected: { ...prevState.tableItemSelected, ...data },
		}), () => {
			this.props.onActionJsonUpdate(this.state.tableItemSelected, this.getReviewData(key, value));
		});
	}

	fieldUpdate(event) {
		const newValue = event.target.value.replace(/["']/g, "");
		const name = event.target.name;
		this.setState((prevState) => ({
			tableItemSelected: { ...prevState.tableItemSelected, [name]: newValue },
		}), () => {
			this.props.onActionJsonUpdate(this.state.tableItemSelected, this.getReviewData(name, newValue));
		});
	}

	handleDataJsonChange(event) {
		const newValue = event.target.value;
		const name = event.target.name;
		if (event.target.value === "") {
			this.setState((prevState) => ({
				staticEndpointDataStringValid: true,
				tagsStringValid: true,
				tableItemSelected: { ...prevState.tableItemSelected, [name]: [{}] },
			}), () => {
				this.props.onActionJsonUpdate(this.state.tableItemSelected, this.getJsonReviewData(name, newValue));
			});
		} else {
			try {
				const json = JSON.parse(event.target.value);
				this.setState((prevState) => ({
					staticEndpointDataStringValid: true,
					tagsStringValid: true,
					tableItemSelected: { ...prevState.tableItemSelected, [name]: json },
				}), () => {
					this.props.onActionJsonUpdate(this.state.tableItemSelected, this.getJsonReviewData(name, newValue));
				});
			} catch (_error) {
				let staticEndpointDataStringValid = true;
				let tagsStringValid = true;
				if (event.target.name === "staticEndpointData") {
					staticEndpointDataStringValid = false;
				} else if (event.target.name === "tags") {
					tagsStringValid = false;
				}
				this.setState((prevState) => ({
					staticEndpointDataStringValid: staticEndpointDataStringValid,
					tagsStringValid: tagsStringValid,
					tableItemSelected: { ...prevState.tableItemSelected, [name]: newValue },
				}));
			}
		}
	}

	getJsonReviewData(name, newValue) {
		const index = this.#reviewData.findIndex((item) => (item && item.label === name));
		const oldValue = JSON.stringify(this.#previousItem[name] ? this.#previousItem[name][0] : {}, null, 2);
		if (index === -1 && oldValue !== newValue) {
			this.#reviewData.push({
				label: name,
				oldVal: oldValue,
				newVal: newValue,
			});
		} else if (oldValue === newValue) {
			this.#reviewData.splice(index, 1);
		} else {
			this.#reviewData[index] = {
				label: name,
				oldVal: oldValue,
				newVal: newValue,
			};
		}
		return this.#reviewData;
	}

	getReviewData(name, newValue) { // TODO: add type
		if (name === "icon") {
			["", "_a", "_b"].forEach((item) => {
				this.processReviewData(`${name}${item}`, `${newValue}${item}`);
			});
			return this.#reviewData;
		} else {
			this.processReviewData(name, newValue);
			return this.#reviewData;
		}
	}

	processReviewData(name, newValue) {
		const index = this.#reviewData.findIndex((item) => (item && item.label === name));
		const newVal = (typeof newValue === "boolean") ? newValue.toString() : newValue;
		const oldVal = (typeof this.#previousItem[name] === "boolean") ? this.#previousItem[name].toString() : this.#previousItem[name];
		const dataObj = {
			label: name,
			oldVal: oldVal,
			newVal: newVal,
		};
		if (index === -1 && this.#previousItem[name] !== newValue) {
			this.#reviewData.push(dataObj);
		} else if (this.#previousItem[name] !== newValue) {
			this.#reviewData[index] = dataObj;
		} else if (index >= 0) {
			this.#reviewData.splice(index, 1);
		}
	}

	getFieldUpdateCount(section) {
		let count = 0;
		this.#reviewData.forEach((data) => {
			if (SECTIONS[section].includes(data.label)) {
				count += 1;
			}
		});
		return count;
	}

	override render() {
		return (
			<Paper component="section" className="card-separator editor-section">
				<InfoSection
					selectedItem={this.state.tableItemSelected}
					previousItem={this.#previousItem}
					fieldUpdateCount={this.getFieldUpdateCount("INFO")}
					fieldUpdate={this.fieldUpdate}
					onChange={this.handleChange}
					onToggleChange={this.handleToggleChange}
				/>
				<IconsSection
					selectedItem={this.state.tableItemSelected}
					previousItem={this.#previousItem}
					fieldUpdateCount={this.getFieldUpdateCount("ICON")}
					// fieldUpdate={this.fieldUpdate}
					onChange={this.handleChange}
					onToggleChange={this.handleToggleChange}
				/>
				<ManufacturerSection
					selectedItem={this.state.tableItemSelected}
					previousItem={this.#previousItem}
					fieldUpdateCount={this.getFieldUpdateCount("MANUFACTURER")}
					fieldUpdate={this.fieldUpdate}
					// onChange={this.handleChange}
					// onToggleChange={this.handleToggleChange}
				/>
				<HelpsSection
					selectedItem={this.state.tableItemSelected}
					previousItem={this.#previousItem}
					fieldUpdateCount={this.getFieldUpdateCount("HELPS")}
					fieldUpdate={this.fieldUpdate}
					// onChange={this.handleChange}
					// onToggleChange={this.handleToggleChange}
				/>
				<AdvancedSection
					selectedItem={this.state.tableItemSelected}
					previousItem={this.#previousItem}
					fieldUpdateCount={this.getFieldUpdateCount("ADVANCED")}
					staticEndpointDataStringValid={this.state.staticEndpointDataStringValid}
					fieldUpdate={this.fieldUpdate}
					onToggleChange={this.handleToggleChange}
					onActionJsonChange={this.handleDataJsonChange}
				/>
				<DeprecatedSection
					selectedItem={this.state.tableItemSelected}
					previousItem={this.#previousItem}
					fieldUpdateCount={this.getFieldUpdateCount("DEPRECATED")}
					tagsStringValid={this.state.tagsStringValid}
					fieldUpdate={this.fieldUpdate}
					onToggleChange={this.handleToggleChange}
					onActionJsonChange={this.handleDataJsonChange}
				/>
			</Paper>
		);
	}

}

export default GuiJsonEditor;
