import { memo, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";
// services
import Constants from "../../services/constants";
// types
import type { ReadonlyDeep } from "type-fest";
import type { ChartData } from "chart.js";
import type { GatewayBucketData } from "../../types/gupport";
import type { GatewaySocketStatus } from "../../types/misc";
// lazy
const Pie = lazy(() => import("../charts/pie-chart"));

type Props = Readonly<{
	container: boolean;
	selectedGatewayStat: ReadonlyDeep<GatewayBucketData> | null;
}>;

const GW_STATUS_BG_COLORS = {
	[Constants.GatewaySocketStatus.EvvrLive]: "#81C784",
	[Constants.GatewaySocketStatus.SercomLive]: "#4CAF50",
	[Constants.GatewaySocketStatus.EvvrOffline]: "#E53935",
	[Constants.GatewaySocketStatus.SercomOffline]: "#D32F2F",
	[Constants.GatewaySocketStatus.Virtual]: "#4682B4",
} as const;

const GatewayStatChart = ({ container, selectedGatewayStat }: Props) => {
	const { t } = useTranslation();

	if (!selectedGatewayStat) {
		return null;
	}
	const { data, bgColors, labels } = selectedGatewayStat.socket_status.buckets.reduce((bucketObj, bucket) => {
		bucketObj.data.push(bucket.count);
		bucketObj.bgColors.push(GW_STATUS_BG_COLORS[bucket.val]);
		bucketObj.labels.push(bucket.val);
		return bucketObj;
	}, { data: [] as Array<number>, bgColors: [] as Array<string | undefined>, labels: [] as Array<GatewaySocketStatus> });

	const pieChartData = {
		datasets: [{
			data: data,
			backgroundColor: bgColors,
			label: t("welcome.gatewayStateTitle"), // for legend
		}],
		labels: labels,
	} as const satisfies ChartData<"pie">;

	return (
		<section style={container ? { width: "50%", float: "left" } : { width: "100%" }}>
			<h3>{t("welcome.gatewayStateTitle")}</h3>
			<Suspense fallback={<Skeleton animation="wave" variant="rectangular" height={43} />}>
				<Pie width={100} height={70} data={pieChartData} />
			</Suspense>
		</section>
	);
};

export default memo(GatewayStatChart);
