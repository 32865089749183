import { Component } from "react";
import {
	Divider,
	List,
	ListItemButton,
	ListItemText,
	ListSubheader,
} from "@mui/material";
// services
import Constants from "../../services/constants";
// types
import type { ApiSchemasTable, ApiSchemaId } from "../../types/roc-table";

type Props = Readonly<{
	schemas: ApiSchemasTable;
	rbacs: Array<any>;
	onChange: (schemaId: ApiSchemaId, json: any) => void;
}>;

type State = {
	selectedSchema: any;
	actionJson: any;
};

class ActionBuilder extends Component<Props, State> {

	public static defaultProps = {
		schemas: null,
		onChange: null,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			selectedSchema: null,
			actionJson: "{\n}",
		};
	}

	handleActionChange(value) {
		const schemaChanged = this.props.schemas.find((schema) => (schema.id.string_key === value));
		const json = JSON.stringify(schemaChanged.data.payload, null, 2);

		this.setState({
			selectedSchema: value,
			actionJson: json,
		}, () => {
			if (this.props.onChange) {
				this.props.onChange(this.state.selectedSchema, this.state.actionJson);
			}
		});
	}

	renderActionItems() {
		const actionList = [];

		this.props.rbacs.forEach((rbac, index) => {
			if (index > 0) {
				actionList.push(<Divider key={`${rbac.name} -divider`} />);
			}
			actionList.push(<ListSubheader disableSticky={true} key={`${rbac.name}-header`}>{rbac.name}</ListSubheader>);

			rbac.apis.filter((schema) => (
				schema.data.dir === "TX" && (schema.data.api === Constants.WsType.Glient || schema.data.api === Constants.WsType.Gupport)
			)).forEach((schema) => {
				actionList.push(
					<ListItemButton
						key={schema.id.string_key}
						onClick={this.handleActionChange.bind(this, schema.id.string_key)}
					>
						<ListItemText primary={schema.data.label} secondary={schema.data.description} />
					</ListItemButton>
				);
			});
		});

		return actionList;
	}

	override render() {
		return (
			<List>
				{this.renderActionItems()}
			</List>
		);
	}

}

export default ActionBuilder;
