import { useTranslation } from "react-i18next";
import {
	Paper,
	Table,
	TableBody,
	TableHead,
	TableCell,
	TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import Na from "../../na";

type Props = Readonly<{
	selectedItem: any;
}>;

const DEFAULT_SELECTED_ITEM = {
	id: {
		string_key: "",
	},
	data: {
		mfg_name: "",
		mfg_model: "",
		type: "",
		category: "",
	},
};

const EditableRowInfo = ({ selectedItem = DEFAULT_SELECTED_ITEM }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const columnStyle = {
		paddingRight: "5px",
		paddingLeft: "30px",
		whiteSpace: "normal",
		overflow: "inherit",
		wordBreak: "break-word",
	};

	const headingStyle = {
		paddingRight: "5px",
		paddingLeft: "30px",
	};
	return (
		<Paper className="card-separator">
			<Table>
				<TableHead>
					<TableRow>
						<TableCell sx={headingStyle}>{t("teditor.key")}</TableCell>
						<TableCell sx={headingStyle}>{t("teditor.name")}</TableCell>
						<TableCell sx={headingStyle}>{t("teditor.type")}</TableCell>
						<TableCell sx={headingStyle}>{t("teditor.category")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow className="last-row-no-border" sx={{ height: "62px" }}>
						<TableCell sx={columnStyle} padding="none">{selectedItem.id.string_key ?? <Na />}</TableCell>
						<TableCell sx={columnStyle} padding="none">
							<div>{selectedItem.data.mfg_name ?? <Na />}</div>
							<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
								{selectedItem.data.mfg_model ?? ""}
							</div>
						</TableCell>
						<TableCell sx={columnStyle} padding="none">
							<div>{t(`rocIds.${selectedItem.data.type}`) || <Na />}</div>
							<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
								{selectedItem.data.type ?? ""}
							</div>
						</TableCell>
						<TableCell sx={columnStyle} padding="none">
							<div>{t(`deviceCategories.${selectedItem.data.category}`) || <Na />}</div>
							<div style={{ color: theme.palette.text.secondary, fontSize: "11px" }}>
								{selectedItem.data.category ?? ""}
							</div>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Paper>
	);
};

export default EditableRowInfo;
