import { useTranslation } from "react-i18next";
import { IconButton, Button } from "@mui/material";
import Svg from "../svg";
// services
import { icons } from "@local/theme";
// types
import type { TableNameId } from "../../types/gupport";

type Props = Readonly<{
	isCustomEditor: boolean;
	hasWriteAccess: boolean;
	tableItemSelected: any; //TODO
	onClickHandler: (isDuplicate: boolean) => void;
	tableItems: any; //TODO
	tableId: TableNameId;
	loading: boolean;
}>;

const TableEditorActions = ({ isCustomEditor = false, hasWriteAccess, tableItemSelected = null, onClickHandler, tableItems, tableId, loading }: Props) => {
	const { t } = useTranslation();

	const handleExportClick = () => {
		const jsonData = JSON.stringify(tableItems, null, 2);
		const blob = new Blob([jsonData], { type: "application/json" });
		const url = URL.createObjectURL(blob);
		const tempLink = document.createElement("a");
		tempLink.href = url;
		tempLink.setAttribute("download", `${tableId}.json`);
		tempLink.click();
	};

	return (
		<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: (isCustomEditor ? "12px 0" : "0"), width: "100%" }}>
			<div style={{ fontWeight: "bold", marginLeft: "5px" }}>{t("teditor.objects")}</div>
			{isCustomEditor ?
				<div>
					<Button
						variant="contained"
						disabled={!hasWriteAccess}
						startIcon={<Svg src="navigation/add.svg" />}
						onClick={() => (onClickHandler(false))}
					>
						{t("teditor.newEntry")}
					</Button>
					<Button
						variant="contained"
						disabled={loading}
						startIcon={<icons.Download />}
						onClick={handleExportClick}
						style={{ marginLeft: "8px" }}
					>
						{t("gateway.exportTable")}
					</Button>
				</div>
				:
				<div>
					<IconButton
						title={t("teditor.add")}
						disabled={!hasWriteAccess}
						onClick={() => (onClickHandler(false))}
					>
						<Svg src="navigation/add.svg" />
					</IconButton>
					<IconButton
						title={t("teditor.duplicate")}
						disabled={(tableItemSelected === null || !hasWriteAccess)}
						onClick={() => (onClickHandler(true))}
					>
						<icons.CopyContent />
					</IconButton>
					<Button
						variant="contained"
						size="small"
						disabled={loading}
						startIcon={<icons.Download />}
						onClick={handleExportClick}
						style={{ marginLeft: "8px" }}
					>
						{t("gateway.exportTable")}
					</Button>
				</div>
			}
		</div>
	);
};

export default TableEditorActions;
