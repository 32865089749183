import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	ListItemText,
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Select,
	MenuItem,
	Switch,
} from "@mui/material";
// cmps
import RowEntry from "../row-entry";
// hooks
import useSorter from "../../hooks/useSorter";
// services
import TemplateJsons from "../../services/template-jsons";

type TemplateKey = keyof (typeof TemplateJsons);

const TEMPLATE_KEYS = Object.keys(TemplateJsons) as ReadonlyArray<TemplateKey>;

type Props = Readonly<{
	newKey: string;
	newKeyType: string;
	rocIdPrefixes: Record<string, any>; // TODO
	isDuplicate: boolean;
	isRocIdDict: boolean;
	onAddClick: (key: string, templateJson: any) => void;
	checkForExistingKeys: (key: string) => boolean;
	onCloseHandler: () => void;
}>;

const DEFAULT_ROC_ID_PREFIXES = {} as const satisfies Record<string, any>; // TODO

const AddNewEntryDialog = ({ newKey, newKeyType, rocIdPrefixes = DEFAULT_ROC_ID_PREFIXES, isDuplicate, isRocIdDict, onAddClick, checkForExistingKeys, onCloseHandler }: Props) => {
	const { t } = useTranslation();

	const sortAlphabetically = useSorter();

	const [adding, setAdding] = useState(false);
	const [value, setValue] = useState(newKey);
	const [prefix, setPrefix] = useState(rocIdPrefixes.DEFAULT ?? "");
	const [error, setError] = useState(false);
	const [helperText, setHelperText] = useState("");
	const [templateEnabled, setTemplateEnabled] = useState(false);
	const [templateType, setTemplateType] = useState<TemplateKey | undefined>(undefined);

	const getHelperText = (error) => {
		if (error) {
			return isRocIdDict ? t("teditor.keyErrorText") : t("teditor.duplicateKeyError");
		} else {
			return isRocIdDict ? t("teditor.keyValidText") : "";
		}
	};

	useEffect(() => {
		if (isDuplicate && isRocIdDict) {
			const prefix = Object.keys(rocIdPrefixes).find((prefix) => (newKey.startsWith(prefix)));
			if (prefix) {
				setPrefix(rocIdPrefixes[prefix]);
			} else if (!Number.isNaN(Number(newKey))) {
				setPrefix(rocIdPrefixes.DEFAULT);
			}
		}
		setValue(newKey);
		setError(checkForExistingKeys(newKey));
		setHelperText(getHelperText(checkForExistingKeys(newKey)));
	}, [newKey]);

	const handleNewKeyChange = (event) => {
		const text = event.target.value;
		if (newKeyType === "object") {
			try {
				setError(checkForExistingKeys(text));
				setHelperText(getHelperText(checkForExistingKeys(text)));
			} catch (_error) {
				setError(true);
				setHelperText(t("teditor.invalidKeyText"));
			}
		} else {
			setError(checkForExistingKeys(text));
			setHelperText(getHelperText(checkForExistingKeys(text)));
		}
		setValue(text);
	};

	const handlePrefixChange = (event) => {
		const text = event.target.value;
		setPrefix(text);
		setValue(text);
		setError(checkForExistingKeys(text));
		setHelperText(getHelperText(checkForExistingKeys(text)));
	};

	const handleToggleChange = (event) => {
		setTemplateEnabled(event.target.checked);
		setTemplateType(undefined);
	};

	const handleTemplateChange = (event) => {
		setTemplateType(event.target.value);
	};

	const handleAddClick = () => {
		setAdding(true);

		const getTemplateJson = () => {
			if (templateType === undefined) {
				if (isRocIdDict) {
					return {
						rocid: value,
					};
				}
			} else {
				const templateJson = TemplateJsons[templateType];
				if (templateJson.rocid === "{{ROCID}}") {
					return {
						...templateJson,
						rocId: value,
					};
				}
			}
			return null;
		};
		onAddClick(value, getTemplateJson());
	};

	const templateList = TEMPLATE_KEYS.toSorted(sortAlphabetically);

	return (
		<Dialog
			fullWidth={true}
			open={true}
			onClose={onCloseHandler}
		>
			<DialogTitle>{t("teditor.chooseKey")}</DialogTitle>
			<DialogContent>
				{isRocIdDict &&
					<RowEntry title={t("teditor.key")} titleWidth={40} styleRight={{ margin: "-8px 0" }}>
						<Select
							fullWidth={true}
							value={prefix}
							onChange={handlePrefixChange}
						>
							{Object.keys(rocIdPrefixes).map((key) => (
								<MenuItem key={key} value={rocIdPrefixes[key]}>
									<ListItemText primary={key} />
								</MenuItem>
							))}
						</Select>
					</RowEntry>
				}
				<RowEntry title={isRocIdDict ? t("teditor.rocId") : t("teditor.key")} titleWidth={40} styleRight={{ margin: "-8px 0" }}>
					<TextField
						placeholder="0000"
						fullWidth={true}
						multiline={true}
						slotProps={{
							inputLabel: {
								shrink: true,
							},
						}}
						value={value}
						error={error}
						helperText={helperText}
						onChange={handleNewKeyChange}
					/>
				</RowEntry>
				{(!isDuplicate && isRocIdDict) &&
					<RowEntry title={t("teditor.chooseTemplate")} titleWidth={40} styleRight={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "-8px 0" }}>
						<Switch
							checked={templateEnabled}
							onChange={handleToggleChange}
						/>
						<Select
							fullWidth={true}
							value={templateType}
							disabled={!templateEnabled}
							onChange={handleTemplateChange}
						>
							{templateList.map((key) => (
								<MenuItem key={key} value={key}>
									<ListItemText primary={key} />
								</MenuItem>
							))}
						</Select>
					</RowEntry>
				}
			</DialogContent>
			<DialogActions>
				<Button
					disabled={((templateEnabled && templateType === undefined) || value === "" || error)}
					loading={adding}
					onClick={handleAddClick}
				>
					{t("dialog.add")}
				</Button>
				<Button color="inherit" onClick={onCloseHandler}>{t("dialog.cancel")}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddNewEntryDialog;
