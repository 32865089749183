import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Divider,
	Collapse,
	List,
	ListItemButton,
	ListItemText,
	Avatar,
	Typography,
	ListItemIcon,
	TextField,
	Switch,
	Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import RowEntry from "../../../row-entry";
import Svg from "../../../svg";
// services
import { icons } from "@local/theme";
// types
import type { ChangeEvent, SyntheticEvent } from "react";

type Props = Readonly<{
	selectedItem: any;
	previousItem: any;
	tagsStringValid: boolean;
	fieldUpdateCount: number;
	fieldUpdate: (event: SyntheticEvent) => void;
	onToggleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	onActionJsonChange: (event: SyntheticEvent) => void;
}>;

const DEFAULT_SELECTED_ITEM = {
	device_details_native: false,
	help: "",
	tags: null,
};

const DeprecatedSection = ({ selectedItem = DEFAULT_SELECTED_ITEM, previousItem, fieldUpdateCount, tagsStringValid, fieldUpdate, onToggleChange, onActionJsonChange }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(false);

	const hasJsonChanged = (key) => {
		try {
			if (selectedItem[key] && selectedItem[key] !== null) {
				if (typeof selectedItem[key][0] === "string") {
					return selectedItem[key][0] !== JSON.stringify(previousItem[key] ?? {}, null, 2);
				}
				return JSON.stringify(selectedItem[key][0], null, 2) !== JSON.stringify(previousItem[key] ?? {}, null, 2);
			}
			return false;
		} catch (_error) {
			return false;
		}
	};

	return (
		<List disablePadding={true}>
			<ListItemButton
				onClick={() => (setExpanded(!expanded))}
				style={{ paddingTop: "12px", paddingBottom: "12px" }}
			>
				<ListItemText
					primary={
						<Typography variant="body2" style={{ fontWeight: "bold" }}>
							{t("customtEditor.sections.deprecated")}
						</Typography>
					}
				/>
				<Avatar className="count-badge">{fieldUpdateCount}</Avatar>
				<ListItemIcon>
					{expanded
						? <icons.ExpandLess htmlColor={theme.palette.text.secondary} />
						: <Svg src="navigation/arrowDropDown.svg" color={theme.palette.text.secondary} />
					}
				</ListItemIcon>
			</ListItemButton>
			<Divider />
			<Collapse in={expanded} timeout="auto">
				<RowEntry title={t("customtEditor.device_details_native.text")} subTitle={t("customtEditor.device_details_native.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.device_details_native !== selectedItem.device_details_native &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.device_details_native === undefined ? "NA" : previousItem.device_details_native.toString()}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<div style={{ width: "calc(100% - 56px)" }}>
						<Switch
							name="device_details_native"
							checked={selectedItem.device_details_native ?? false}
							onChange={onToggleChange}
						/>
					</div>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.help.text")} subTitle={t("customtEditor.help.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.help !== selectedItem.help &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.help ?? "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="help"
						placeholder="https://"
						value={selectedItem.help ?? ""}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.tags.text")} subTitle={t("customtEditor.tags.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{(hasJsonChanged("tags") && tagsStringValid) &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={
								(previousItem.tags && previousItem.tags !== null) ?
									((typeof previousItem.tags[0] === "string") ?
										previousItem.tags[0]
										:
										JSON.stringify(previousItem.tags[0], null, 2))
									:
									"NA"
							}
							>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						name="tags"
						value={
							(selectedItem.tags && selectedItem.tags !== null) ?
								((typeof selectedItem.tags[0] === "string") ?
									selectedItem.tags[0]
									:
									JSON.stringify(selectedItem.tags[0], null, 2))
								:
								""
						}
						placeholder="{}"
						multiline={true}
						fullWidth={true}
						error={!tagsStringValid}
						onChange={onActionJsonChange}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
			</Collapse>
		</List>
	);
};

export default DeprecatedSection;
