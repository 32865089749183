import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

type Props = Readonly<{
	open: boolean;
	handleConfirmClick: () => void;
	handleClose: () => void;
}>;

const WarningDialog = (props: Props) => {
	const { t } = useTranslation();
	return (
		<Dialog
			fullWidth={true}
			open={props.open}
			onClose={props.handleClose}
		>
			<DialogTitle>{t("teditor.warningTitle")}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t("teditor.warningMsg")}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.handleConfirmClick} style={{ marginRight: 5 }}>{t("dialog.confirm")}</Button>
				<Button color="inherit" onClick={props.handleClose}>{t("dialog.cancel")}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default WarningDialog;
