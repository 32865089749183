import { Component } from "react";
import { withTranslation } from "react-i18next";
import {
	Card,
	CardActions,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	CardContent,
} from "@mui/material";
// services
import Gupport from "../../services/gupport";
// types
import type { WithTranslation } from "react-i18next";
import type { UrlHTTP } from "../../types/misc";
import type { CmdImportBackup } from "../../types/gupport";

type Props = Readonly<WithTranslation>;

type State = {
	openErrorDialog: boolean;
	openSuccessDialog: boolean;
	backupUrl: UrlHTTP | null;
};

class ImportBackup extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			openErrorDialog: false,
			openSuccessDialog: false,
			backupUrl: null,
		};

		this.handleClose = this.handleClose.bind(this);
		this.importBackup = this.importBackup.bind(this);
		this.handleActionUrlChange = this.handleActionUrlChange.bind(this);
	}

	handleClose() {
		this.setState({
			openSuccessDialog: false,
			openErrorDialog: false,
		});
	}

	handleActionUrlChange(event) {
		this.setState({
			backupUrl: event.target.value,
		});
	}

	importBackup() {
		if (this.state.backupUrl) {
			const cmd = {
				action: "importBackup",
				metaUrl: this.state.backupUrl,
			} as const satisfies CmdImportBackup;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					this.setState({
						openSuccessDialog: true,
					});
				} else {
					this.setState({
						openErrorDialog: true,
					});
				}
			});
		}
	}

	override render() {
		const { t } = this.props;
		return (
			<Card style={{ paddingTop: "5px" }}>
				<CardContent style={{padding: "0px 16px"}}>
					<TextField
						placeholder="https://"
						autoFocus={true}
						label={t("backup.label")}
						fullWidth={true}
						onChange={this.handleActionUrlChange}
						className="editor"
					/>
				</CardContent>
				<CardActions style={{ float: "right" }}>
					<Button variant="contained" onClick={this.importBackup}>
						{t("backup.importBtn")}
					</Button>
				</CardActions>
				<Dialog
					fullWidth={true}
					open={this.state.openErrorDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("backup.importErrorTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t("backup.importErrorMsg")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullWidth={true}
					open={this.state.openSuccessDialog}
					onClose={this.handleClose}
				>
					<DialogTitle>{t("backup.importSuccessTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{t("backup.importSuccessMsg")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
			</Card>
		);
	}

}

export default withTranslation()(ImportBackup);
