import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type Props = Readonly<{
	dataString: string;
	onActionJsonChange: (value: string) => void;
}>;

const MetadataJSONEditor = ({ dataString, onActionJsonChange }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [jsonData, setJsonData] = useState(dataString);
	const timeoutIdRef = useRef<number | undefined>(undefined);

	useEffect(() => (
		() => {
			window.clearTimeout(timeoutIdRef.current);
		}
	), []);

	useEffect(() => {
		setJsonData(dataString);
	}, [dataString]);

	useEffect(() => {
		window.clearTimeout(timeoutIdRef.current);

		timeoutIdRef.current = window.setTimeout(() => {
			onActionJsonChange(jsonData);
		}, 500);
	}, [jsonData]);

	return (
		<TextField
			placeholder="{}"
			label={t("playground.json")}
			fullWidth={true}
			multiline={true}
			value={jsonData}
			onChange={(event) => (setJsonData(event.target.value))}
			style={{ backgroundColor: theme.palette.background.paper }}
		/>
	);
};

export default MetadataJSONEditor;
