import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { images, hasImage } from "@local/theme";
// types
import type { ImgHTMLAttributes } from "react";
import type { Theme, SxProps } from "@mui/material";

type Props = Readonly<ImgHTMLAttributes<HTMLImageElement> & {
	src: string;
	size?: number;
	sx?: SxProps<Theme>;
}>;

const Image = ({ src, title = undefined, alt = undefined, size = undefined, sx = undefined, ...other }: Props) => {
	const theme = useTheme();

	const _src = src.startsWith("http")
		? src
		: (theme.palette.mode === "dark" && hasImage(`dark/${src}`))
			? images(`dark/${src}`)
			: images(src);

	return (
		<Box
			component="img"
			src={_src}
			title={title}
			alt={alt ?? title}
			width={size}
			height={size}
			sx={{ verticalAlign: "middle", ...sx ?? {} }} // eslint-disable-line @typescript-eslint/no-misused-spread
			{...other}
		/>
	);
};

export default Image;
