import { Card, CardContent, ListSubheader } from "@mui/material";
// types
import type { ReactNode } from "react";

type Props = Readonly<{
	title: string;
	children: ReactNode;
}>;

const PlaygroundCard = ({ title, children }: Props) => (
	<div style={{padding: "2px", marginTop: "12px"}}>
		<Card>
			<ListSubheader>{title}</ListSubheader>
			<CardContent>
				{children}
			</CardContent>
		</Card>
	</div>
);

export default PlaygroundCard;
