import { useCallback } from "react";
// hooks
import useLocale from "./useLocale";

const OPTIONS = {
	sensitivity: "base",
} as const satisfies Intl.CollatorOptions;

const useSorter = (options: Intl.CollatorOptions = OPTIONS): Intl.Collator["compare"] => {
	const { baseName } = useLocale();

	const compare = useCallback(new Intl.Collator(baseName, options).compare, [baseName, options]);

	return compare;
};

export default useSorter;
