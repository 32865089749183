import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Divider,
	Collapse,
	Link,
	List,
	ListItemButton,
	ListItemText,
	Avatar,
	MenuItem,
	Typography,
	ListItemIcon,
	Select,
	IconButton,
	TextField,
	Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import RowEntry from "../../../row-entry";
import Svg from "../../../svg";
// services
import Constants from "../../../../services/constants";
import { icons } from "@local/theme";

interface Manufacturer {
	device_info: string;
	mfg_fw: string;
	mfg_help: string;
	mfg_model: string;
	mfg_name: string;
	public_mfg_fw: string;
	public_mfg_model: string;
	public_mfg_name: string;
}

type Props = Readonly<{
	selectedItem?: Manufacturer;
	previousItem: Manufacturer;
	fieldUpdateCount: number;
	fieldUpdate: (event: any) => void;
}>;

const DEFAULT_SELECTED_ITEM = {
	device_info: "hide",
	mfg_fw: "",
	mfg_help: "",
	mfg_model: "",
	mfg_name: "",
	public_mfg_fw: "",
	public_mfg_model: "",
	public_mfg_name: "",
} as const satisfies Manufacturer;

const ManufacturerSection = ({ selectedItem = DEFAULT_SELECTED_ITEM, previousItem, fieldUpdateCount, fieldUpdate }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(false);

	return (
		<List disablePadding={true}>
			<ListItemButton
				onClick={() => (setExpanded(!expanded))}
				style={{ paddingTop: "12px", paddingBottom: "12px" }}
			>
				<ListItemText
					primary={
						<Typography variant="body2" style={{ fontWeight: "bold" }}>
							{t("customtEditor.sections.manufacturer")}
						</Typography>
					}
				/>
				<Avatar className="count-badge">{fieldUpdateCount}</Avatar>
				<ListItemIcon>
					{expanded
						? <icons.ExpandLess htmlColor={theme.palette.text.secondary} />
						: <Svg src="navigation/arrowDropDown.svg" color={theme.palette.text.secondary} />
					}
				</ListItemIcon>
			</ListItemButton>
			<Divider />
			<Collapse in={expanded} timeout="auto">
				<RowEntry title={t("customtEditor.device_info.text")} subTitle={t("customtEditor.device_info.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.device_info !== selectedItem.device_info &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.device_info || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<Select
						name="device_info"
						value={selectedItem.device_info}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					>
						{
							Constants.DeviceInfoValues.map((value) => (
								<MenuItem key={value} value={value}>
									<ListItemText primary={value} />
								</MenuItem>
							))
						}
					</Select>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.mfg_name.text")} subTitle={t("customtEditor.mfg_name.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.mfg_name !== selectedItem.mfg_name &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.mfg_name || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="mfg_name"
						value={selectedItem.mfg_name || ""}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.mfg_model.text")} subTitle={t("customtEditor.mfg_model.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.mfg_model !== selectedItem.mfg_model &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.mfg_model || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="mfg_model"
						value={selectedItem.mfg_model || ""}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.mfg_help.text")} subTitle={t("customtEditor.mfg_help.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.mfg_help !== selectedItem.mfg_help &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.mfg_help || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						name="mfg_help"
						placeholder="https://"
						value={selectedItem.mfg_help || ""}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 112px)", marginTop: "10px" }}
					/>
					<ListItemIcon>
						<IconButton disabled={!previousItem.mfg_help} component={Link} href={previousItem.mfg_help} target="_blank" style={{ marginLeft: "8px" }}>
							<Svg src="navigation/openNewWindow.svg" color={previousItem.mfg_help ? theme.palette.primary.main : theme.palette.text.secondary} />
						</IconButton>
					</ListItemIcon>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.mfg_fw.text")} subTitle={t("customtEditor.mfg_fw.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.mfg_fw !== selectedItem.mfg_fw &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.mfg_fw || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="mfg_fw"
						placeholder="v0.0"
						value={selectedItem.mfg_fw || ""}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.public_mfg_name.text")} subTitle={t("customtEditor.public_mfg_name.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.public_mfg_name !== selectedItem.public_mfg_name &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.public_mfg_name || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="public_mfg_name"
						placeholder=""
						value={selectedItem.public_mfg_name || ""}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.public_mfg_model.text")} subTitle={t("customtEditor.public_mfg_model.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.public_mfg_model !== selectedItem.public_mfg_model &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.public_mfg_model || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="public_mfg_model"
						placeholder=""
						value={selectedItem.public_mfg_model || ""}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
				<Divider />
				<RowEntry title={t("customtEditor.public_mfg_fw.text")} subTitle={t("customtEditor.public_mfg_fw.id")} styleRight={{ margin: "-8px 0", display: "flex", justifyContent: "flex-end" }}>
					{previousItem.public_mfg_fw !== selectedItem.public_mfg_fw &&
						<ListItemIcon style={{ alignItems: "center" }}>
							<Tooltip title={previousItem.public_mfg_fw || "NA"}>
								<Svg src="actions/editLine.svg" color={theme.palette.primary.main} />
							</Tooltip>
						</ListItemIcon>
					}
					<TextField
						fullWidth={true}
						name="public_mfg_fw"
						placeholder=""
						value={selectedItem.public_mfg_fw || ""}
						onChange={fieldUpdate}
						style={{ width: "calc(100% - 56px)" }}
					/>
				</RowEntry>
			</Collapse>
		</List>
	);
};

export default ManufacturerSection;
