import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

type Props = Readonly<{
	open: boolean;
	handleClose: () => void;
	handleRemoveClick: () => void;
}>;

const DeleteDialog = (props: Props) => {
	const { t } = useTranslation();
	return (
		<Dialog
			fullWidth={true}
			open={props.open}
			onClose={props.handleClose}
		>
			<DialogTitle>{t("teditor.deleteTitle")}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t("teditor.deleteMsg")}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="danger" onClick={props.handleRemoveClick} style={{ marginRight: 5 }}>{t("teditor.remove")}</Button>
				<Button color="inherit" onClick={props.handleClose}>{t("dialog.cancel")}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;
