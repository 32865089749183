import { useTranslation } from "react-i18next";
import {
	Button,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	ListItemIcon,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import Svg from "../../svg";
import Na from "../../na";
// services
import { sortAlphabetically } from "../../../services/l10n";

type Props = Readonly<{
	reviewData: Array<any>;
	open: boolean;
	onSave: (value: boolean) => void;
	onClose: () => void;
}>;

const ReviewDialog = (props: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const columnStyle = {
		paddingRight: "5px",
		paddingLeft: 0,
		whiteSpace: "normal",
		overflow: "inherit",
		wordBreak: "break-all",
		width: "35%",
	};
	const attrColumnStyle = {
		paddingRight: "5px",
		paddingLeft: "30px",
		width: "25%",
	};

	const sortReviewData = (reviewData) => (
		reviewData.sort((a, b) => (sortAlphabetically(a.label.toLowerCase(), b.label.toLowerCase())))
	);

	return (
		<Dialog
			className="review-info"
			fullWidth={true}
			maxWidth="lg"
			open={props.open}
			onClose={props.onClose}
		>
			<DialogTitle>{t("customtEditor.reviewTitle")}</DialogTitle>
			<DialogContent sx={{padding: 0}}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={attrColumnStyle}>{t("customtEditor.label")}</TableCell>
							<TableCell sx={columnStyle}>{t("customtEditor.oldValue")}</TableCell>
							<TableCell sx={{width: "5%"}} />
							<TableCell sx={columnStyle}>{t("customtEditor.newValue")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortReviewData(props.reviewData).map((data) => (
							<TableRow key={data.label} className="last-row-no-border" sx={{ height: "62px" }}>
								<TableCell sx={attrColumnStyle} padding="none">
									<div style={{fontWeight: 500}}>{t(`customtEditor.${data.label}.text`)}</div>
									<div style={{color: "rgb(117, 117, 117)", fontSize: "11px", fontFamily: "monospace"}}>
										{t(`customtEditor.${data.label}.id`)}
									</div>
								</TableCell>
								<TableCell sx={columnStyle} padding="none">
									{(["staticEndpointData", "tags", "raw_editor"].includes(data.label) && data.oldVal)
										? <pre>{JSON.stringify(JSON.parse(data.oldVal), null, 2)}</pre>
										: data.oldVal ?? <Na />
									}
								</TableCell>
								<TableCell sx={{width: "5%"}} padding="none">
									<ListItemIcon>
										<Svg src="navigation/arrowRight.svg" color={theme.palette.primary.main} sx={{marginTop: "5px"}} />
									</ListItemIcon>
								</TableCell>
								<TableCell sx={columnStyle} padding="none">
									{(["staticEndpointData", "tags", "raw_editor"].includes(data.label) && data.newVal)
										? <pre>{JSON.stringify(JSON.parse(data.newVal), null, 2)}</pre>
										: data.newVal ?? <Na />
									}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button color="inherit" onClick={props.onClose}>{t("dialog.close")}</Button>
				<Button variant="contained" onClick={() => (props.onSave(true))} sx={{ marginLeft: "10px" }}>
					{t("customtEditor.saveAndBackToList")}
				</Button>
				<Button variant="contained" onClick={() => (props.onSave(false))} sx={{ marginLeft: "10px" }}>
					{t("customtEditor.saveAndContinue")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ReviewDialog;
