import { Autocomplete, ListItemText, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

interface Option {
	id: string | null;
	label: string;
	primary: string;
	secondary: string;
}

type Props = Readonly<{
	name: string;
	placeholder: string;
	value: string;
	options: Array<Option>;
	onChange: (key: string, value: string) => void;
}>;

const EMPTY_OPTION = {
	id: "",
	label: "",
	primary: "<EMPTY>",
	secondary: "",
} as const satisfies Option;

const handleFilterOptions = createFilterOptions({ stringify: (option: Option) => (`${option.primary}#${option.secondary}`) });

const EditableSelectList = ({ name, placeholder, value, options, onChange }: Props) => {
	const _options = (value === "" || options.some((option) => (option.id === value)))
		? [EMPTY_OPTION, ...options] as const
		: [{id: value, label: value, primary: "<UNKNOWN>", secondary: value}, EMPTY_OPTION, ...options] as const;

	return (
		<Autocomplete
			id={`autocomplete_${name}`}
			fullWidth={true}
			options={_options}
			filterOptions={(options, state) => (
				handleFilterOptions(options, {...state, inputValue: state.inputValue.replace(/["']/g, "")})
			)}
			isOptionEqualToValue={(option, value) => (option.id === value.id)}
			value={_options.find((option) => (option.id === value))}
			onChange={(event, value/*, reason, details*/) => (
				onChange(name, value?.id ?? "")
			)}
			renderInput={(params) => (
				<TextField {...params} placeholder={placeholder} />
			)}
			renderOption={(props, option/*, state*/) => (
				<li {...props}><ListItemText primary={option.primary} secondary={option.secondary} /></li>
			)}
			style={{ width: "calc(100% - 56px)", float: "right" }}
		/>
	);
};

export default EditableSelectList;
